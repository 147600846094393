import { APIFactory } from "@/api/APIFactory";
export async function getPendingRequestsFromLocalStorage() {
  const pendingRequestKeys = getPendingRequestKeys();
  if (pendingRequestKeys.length > 0) {
    for (const key of pendingRequestKeys) {
      const pendingRequestsJSON = localStorage.getItem(key);
      const informationTobeSent = JSON.parse(pendingRequestsJSON);
      if (informationTobeSent) {
        for (const parameter of informationTobeSent) {
          searchingKey(parameter,key)
            .then((response) => {
              console.log("response", response);
            })
            .catch((e) => {
              if (process.env.VUE_APP_DEBUG_MODE == "true") console.error(e);
            });
        }
        localStorage.removeItem(key);
      } else {
        console.log("there are no pending requests");
        return [];
      }
    }
  }
}

 function searchingKey(parameter,key) {
  return new Promise((resolve, reject) => {
    const MuseumsAPI = APIFactory.get("museums");
    try {
        switch (key) {
          case "pendingRequests":
            MuseumsAPI.registerVisit(parameter.id, parameter.visit);
            break;
          case "pendingRequestregisterMuseum":
            MuseumsAPI.registerVisitMuseumV2(
              parameter.visitv2,
              parameter.mutation,
              parameter.store
            );
            break;
          case "pendingRequestVisitArtworkV2":
            MuseumsAPI.registerVisitArtworkV2(
              parameter.idVisit,
              parameter.idArtwork
            );
            break;
          case "pendingRequestupVisitRouteList":
            MuseumsAPI.updateVisitRouteListV2(
              parameter.idVisit,
              parameter.idRoute
            );
            break;
          case "pendingRequestupdateMuseumV2":
            MuseumsAPI.updateVisitMuseumV2(
              parameter.visitv2,
              parameter.mutation,
              parameter.store
            );
            break;
          case "pendingRequestUpdateVisit":
            MuseumsAPI.updateVisitArtworkV2(
              parameter.idVisit,
              parameter.idArtwork,
              parameter.duration
            );
            break;
          default:
            console.error("Unrecognized key:", parameter);
        }

        resolve("pending requests have been executed");
      
    } catch (error) {
      console.error("Error in searchingKey:", error);
      reject("error executing the requests");
    }
  });
}

function getPendingRequestKeys() {
  const regex = /pendingRequest/;
  const todasLasClaves = Object.keys(localStorage);
  return todasLasClaves.filter((clave) => regex.test(clave));
}
