

<template>
   
    <div class="center">
      <p class="word-inlines"><strong>{{$t('Load')}}</strong><br>
       <strong>{{$t('Incredible')}}</strong>  <br>
       <strong>{{$t('Contents')}}</strong></p>
      <img src="../assets/easyguide.gif" alt="GIF centrado" width="100" height="100">
      
    </div>
  </template>
  
  <script>
    export default {
      name: 'Load',  
      data() {
        
        return {
          mensaje: "Loading"
        };
      },

    };
  </script>
  
  <style scoped>
  
body {
  margin: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  background-color: #f0f0f0;
}

.center {
  /* text-align: center;
  padding: 20px;
  background-color: #fff; */
  text-align: center;
  padding: 20px;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 20rem;
 
}
.word-inlines {
      white-space: pre-line;
      display: inline-block;
    }
  </style>
  