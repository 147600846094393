import { debug } from "@/misc/debug";
import * as mutationTypes from "@/store/mutation-types";
import { findLocalization, getArtworkAudio, getArtworkImage, getArtworkResources } from "@/store/helpers";
import idb from "@/api/base/idb";

const state = {
	/**
	 * Array of the routes of the museum
	 * @type {Array}
	 */
	routes: [],

	/**
	 * Will be true when there is an error fetching routes from the museum
	 * @type {boolean}
	 */
	routesError: false,

	/**
	 * Object representation of the current route
	 * @type {Object}
	 */
	route: null,

	/**
	 * Will be true when there is an error fetching the current route
	 * @type {boolean}
	 */
	routeError: false,

	/**
	 * Array of the highlighted routes of the museum
	 * @type {Array}
	 */
	highlightedRoutes: [],
};

const getters = {

};

const actions = {
	/**
	 * Gets the routes of the museum
	 * @param rootState
	 * @param state
	 * @param commit
	 * @param {boolean} [exhibitions=false] - Get only the routes marked as "isExhibition"
	 * @param {number} [idRoute=null] - Get only the route which id matches this param
	 */
	getRoutes: async ({ rootState, state, commit }, { exhibitions = false, idRoute = null }) => {
		debug.open("getRoutes");
		commit(mutationTypes.SET_ROUTES_ERROR, false);

		if (rootState.museum.museum && rootState.museum.museum.routes) {
			let routes = [];

			for (let route of rootState.museum.museum.routes) {
				if (!idRoute || idRoute === route.idRoute) {
					if ((exhibitions && !route['isExhibition'])
						|| (!exhibitions && route['isExhibition'])) {
						continue;
					}

					let storedRoute = await idb.getFromDB(route.idRoute, 'routes');
					if (storedRoute) {
						debug.log("Found the " + (exhibitions ? " exhibition" : " route") + ' (id: ' + storedRoute.idRoute + ') in the iDB');
						routes.push(storedRoute);
					} else {
						route.artworkCount = 0;
						for (let stepIndex in route.steps) {
							let step = route.steps[stepIndex];
							if (step.idArtwork) {
								route.artworkCount++;
							}
						}
						route.index = routes.length;
						route.locale = findLocalization(route);
						routes.push(route);
					}
				}
			}

			commit(mutationTypes.SET_ROUTES, routes);
			debug.log("Found " + routes.length + (exhibitions ? " exhibitions" : " routes"));
			if (state.routes.length < 1) {
				debug.log("There are no " + (exhibitions ? " exhibitions" : " routes") + " in this museum");
				commit(mutationTypes.SET_ROUTES_ERROR, true);
			}

		} else {
			debug.log("There are no " + (exhibitions ? " exhibitions" : " routes") + " in this museum");
			commit(mutationTypes.SET_ROUTES_ERROR, true);
		}
		debug.close();
	},

	/**
	 * Gets a route from the museum based on the given id
	 * @param state
	 * @param commit
	 * @param {(number|string)} idRoute             - The id of the route
	 * @param {(boolean)} [isExhibitionView=false]  - Indicates if the search should use the exhibitions or not
	 */
	findRoute: async ({ commit, rootState }, { idRoute, isExhibitionView = false }) => {
		debug.open("findRoute");
		commit(mutationTypes.SET_ROUTE_ERROR, false);

		let route = await idb.getFromDB(idRoute, 'routes');
		if (!route) {
			route = rootState.museum.museum.routes.find((route) => {
				return route.idRoute === parseInt(idRoute)
					&& isExhibitionView === route['isExhibition'];
			});
		}
		commit(mutationTypes.SET_ROUTE, route);

		if (!state.route) {
			debug.log("Route " + idRoute + " could not be found in this museum");
			commit(mutationTypes.SET_ROUTE_ERROR, true);
		} else {
			debug.open("Route " + idRoute + " found");

			if (state.route.steps.length) {
				for (let stepIndex in route.steps) {
					let step = route.steps[stepIndex];

					step.locale = findLocalization(step);
					debug.log("Step " + step.idStep + " locale " + (step.locale ? "found" : "not found"));

					if (step.idArtwork) {
						let artworkImage = await getArtworkImage(rootState.museum.museum, step.idArtwork);
						debug.log("Step " + step.idStep + " artwork image " + (artworkImage ? "found" : "not found"));
						let artworkAudio = await getArtworkAudio(rootState.museum.museum, step.idArtwork);
						debug.log("Step " + step.idStep + " artwork audio " + (artworkAudio ? "found" : "not found"));
						step.artwork = {
							idArtwork: step.idArtwork,
							mainImageUrl: artworkImage,
							mainAudioUrl: artworkAudio,
						};
					}

					step.index = stepIndex;
				}
			} else {
				commit(mutationTypes.SET_ROUTE_ERROR, true);
			}
			debug.close();

			route.locale = findLocalization(route);
		}
		debug.close();
	},

	/**
	 * Gets the game from the museum (which is the first route of a kid's museum
	 * @param state
	 * @param commit
	 */
	findGame: async ({ commit, rootState }) => {
		debug.open("findGame");
		commit(mutationTypes.SET_ROUTE_ERROR, false);

		let game = (rootState.museum.museum.routes.length)
			? rootState.museum.museum.routes.find((route) => !route.isExhibition)
			: null;
		commit(mutationTypes.SET_ROUTE, game);

		if (!state.route) {
			debug.log("There is no game in this museum");
			commit(mutationTypes.SET_ROUTE_ERROR, true);
		} else {
			debug.open("A game was found!");

			if (state.route.steps.length) {
				for (let stepIndex in game.steps) {
					let step = game.steps[stepIndex];

					step.locale = findLocalization(step);
					debug.log("Step " + step.idStep + " locale " + (step.locale ? "found" : "not found"));

					if (step.idArtwork) {
						let artworkImage = await getArtworkImage(rootState.museum.museum, step.idArtwork);
						debug.log("Step " + step.idStep + " artwork image " + (artworkImage ? "found" : "not found"));
						let artworkAudio = await getArtworkAudio(rootState.museum.museum, step.idArtwork);
						debug.log("Step " + step.idStep + " artwork audio " + (artworkAudio ? "found" : "not found"));
						let artworkResources = await getArtworkResources(rootState.museum.museum, step.idArtwork);
						step.artwork = {
							idArtwork: step.idArtwork,
							mainImageUrl: artworkImage,
							mainAudioUrl: artworkAudio,
							resources: artworkResources,
						};
					}

					step.index = stepIndex;
				}
			} else {
				commit(mutationTypes.SET_ROUTE_ERROR, true);
			}
			debug.close();

			game.locale = findLocalization(game);
		}
		debug.close();
	},

	/**
	 * Gets the highlighted routes of the museum
	 * @param rootState
	 * @param state
	 * @param commit
	 */
	getHighlightedRoutes: async ({ rootState, state, commit }) => {
		debug.open("getHighlightedRoutes");

		let routes = [];
		let exhibitions = [];

		if (rootState.museum.museum?.highlightedRoutes?.length) {
			for (let idRoute of rootState.museum.museum.highlightedRoutes) {
				let route = rootState.museum.museum.routes?.find(route => route.idRoute === idRoute);


				if (route) {
					route.locale = findLocalization(route);
					let blob = await fetch(route.mainImageUrl).then(r => r.blob());
					const copyRoute = { ...route, mainImageUrl: URL.createObjectURL(blob) };
					if (route.isExhibition) {
						exhibitions.push(copyRoute);
					} else {
						routes.push(copyRoute);
					}
				}
			}
		}

		let highlightedRoutes = routes.concat(exhibitions);
		commit(mutationTypes.SET_HIGHLIGHTED_ROUTES, highlightedRoutes);
		debug.log("Found " + routes.length + " highlighted routes");
		if (state.highlightedRoutes.length < 1) {
			debug.log("There are no highlighted routes in this museum");
		}
		debug.close();
	},
	toDataURL: (url) => fetch(url)
		.then(response => response.blob())
		.then(blob => new Promise((resolve, reject) => {
			const reader = new FileReader();
			reader.onloadend = () => resolve(reader.result);
			reader.onerror = reject;
			reader.readAsDataURL(blob);
		}))
};

const mutations = {
	[mutationTypes.SET_ROUTES]: (state, value) => state.routes = value,
	[mutationTypes.SET_ROUTE]: (state, value) => state.route = value,
	[mutationTypes.SET_ROUTES_ERROR]: (state, value) => state.routesError = value,
	[mutationTypes.SET_ROUTE_ERROR]: (state, value) => state.routeError = value,
	[mutationTypes.SET_HIGHLIGHTED_ROUTES]: (state, value) => state.highlightedRoutes = value,
};

export default {
	moduleName: 'route',
	statePropName: 'data',
	namespaced: true,

	state,
	getters,
	actions,
	mutations
};
