<template>
	<div class="content">
		<vue-headful
			:title="pageTitle"
		/>
		
		<main :style="{ backgroundImage: 'url(' + backgroundImage + ')' }">
			<vue-headful
				title="MuseumMate"
			/>
			<img class="logo" src="@/assets/logo.png" alt="">
		</main>
		
		 <button class="button error">{{ $t("error_museum_deactivated") }}</button>
	</div>
</template>

<script>
import backgroundImage from '@/assets/home.jpg';
import idb from "../api/base/idb";

export default {
	name: "NotFound",
	data() {
		return {
			backgroundImage: backgroundImage,
			urlObject:null
		};
	},
	computed: {
		pageTitle() {
			return 'MuseumMate';
		},
	},

	
	created(){
		this.haveUrl();

	},
	methods:{
		async haveUrl(){
			this.urlObject = await idb.getFromDB(1,'url') ?  await idb.getFromDB(1,'url') : null ;
				const regex = /\/museums\/[^/]+/;
				const match = this.urlObject.url.match(regex);
				const desiredPart = match ? match[0] : null;
				if (this.urlObject && this.urlObject.url) {
                    this.$router.push(desiredPart);
                 }
			}
		}
};
</script>

<style lang="scss" scoped>
@import '../theme/colors';

.content {
	height: 100%;
	
	main {
		position: relative;
		width: 100%;
		height: 100%;
		background-image: var(--bg-image);
		background-color: white;
		background-position: center center;
		background-repeat: no-repeat;
		background-size: cover;
		
		.logo {
			display: flex;
			justify-content: center;
			align-items: center;
			width: 100%;
			height: auto;
			padding-top: 75px;
			
			.logo-image {
				width: 70%;
			}
		}
	}
	
	.button {
		position: fixed;
		bottom: 0;
		width: 100%;
		padding: 30px;
		border: none;
		background-color: $primary-blue;
		color: white;
		text-transform: uppercase;
	}
}
</style>
