function saveToLocalStorage(data, keyLocal) {
  let request = data;
  let cache = [];
  try {
    let arrayPendingRequest = [];
    const storedPendingRequests = localStorage.getItem(keyLocal);
    if (storedPendingRequests === null) {
      arrayPendingRequest.push(request);
      localStorage.setItem(
        keyLocal,
        JSON.stringify(arrayPendingRequest, function (keyLocal, value) {
          if (typeof value === "object" && value !== null) {
            if (cache.includes(value)) {
              return;
            }
            cache.push(value);
          }
          return value;
        })
      );
    } else {
      let temp = JSON.parse(localStorage.getItem(keyLocal));
      arrayPendingRequest.push(...temp, request);
      localStorage.setItem(
        keyLocal,
        JSON.stringify(arrayPendingRequest, function (keyLocal, value) {
          if (typeof value === "object" && value !== null) {
            if (cache.includes(value)) {
              return;
            }
            cache.push(value);
          }
          return value;
        })
      );
    }
  } catch (error) {
    console.error(
      "error when saving to  storage in saveTolocalStorage:",
      error
    );
  }
}

export default saveToLocalStorage;
