<template>
	<div id="app"  :class="{ 'mode': !isLoading, 'background': isLoading}" :style="cssFont">
		<router-view :key="$route.fullPath"/>
		<new-content-available-toastr
			v-if="newContentAvailable && !userDecidedToNoUpdate"
			class="new-content-available-toastr"
			:refreshing-app="refreshingApp"
			@refresh="serviceWorkerSkipWaiting"
			@cancel="userDecidedToNoUpdate = true"
		/>
	</div>
</template>

<script>
const {debug} = require("@/misc/debug");
import 'firebase/auth';
import 'firebase/analytics';
import { setOptions, bootstrap } from 'vue-gtag';

import firebase from 'firebase/app';


import { mapActions, mapGetters, mapState } from "vuex";
import NewContentAvailableToastr from '@/components/NewContentAvailableToastr';
import MuseumsAPI from "@/api/MuseumsAPI";
import compareDatesToday from './utils/compareDatesToday';
import * as mutationTypes from "@/store/mutation-types";

export default {
	name: 'App',
	
	components: {
		NewContentAvailableToastr
	},
	
	data() {
		return {
			isFirebaseInitialized: false,
			userDecidedToNoUpdate: false,
			mode: localStorage.getItem('mode'),
		};
	},
	
	computed: {
		...mapState({
			museum: state => state.museum.museum,
			refreshingApp: state => state.app.refreshingApp,
			visit: state => state.app.visit,
			isLoading: state => state.app.isLoading,
		}),
		
		...mapGetters('app', ['newContentAvailable']),
		cssFont() {
		if (this.museum && this.museum.code) {
		const isMuseumPicasso = this.museum.code === 'MPICASSOM';
		const fontFamily = isMuseumPicasso ? "'Avenir', sans-serif" : "'Montserrat', sans-serif";

		return `
			*:not(.step-richText) {
			padding: 0;
			margin: 0;
			font-size: 16px;
			box-sizing: border-box;
			font-family: ${fontFamily};

			&:focus {
				outline: none;
			}
			}
		`;
		} else {
		
		return '';
		}
  }

	},
	mounted() {
		this.startVisit();
	},

	updated() {
		if (this.museum && !this.isFirebaseInitialized) {
			this.enablePlugin(this.museum.googleAnalyticsId);
		}
	},
	
	methods: {
		...mapActions('app', ['serviceWorkerSkipWaiting']),
		enablePlugin(googleAnalyticsId) {
			setOptions({
				config: {id: googleAnalyticsId}
			});

			bootstrap().then(() => {
				this.isFirebaseInitialized = true;
				const configOptions = {
					apiKey: process.env.VUE_APP_FIREBASE_API_KEY,
					authDomain: process.env.VUE_APP_FIREBASE_AUTH_DOMAIN,
					databaseURL: process.env.VUE_APP_FIREBASE_DATABASE_URL,
					projectId: process.env.VUE_APP_FIREBASE_PROJECT_ID,
					storageBucket: process.env.VUE_APP_FIREBASE_STORAGE_BUCKET,
					messagingSenderId: process.env.VUE_APP_FIREBASE_MESSAGING_SENDER_ID,
					appId: process.env.VUE_APP_FIREBASE_APP_ID
				};
				firebase.initializeApp(configOptions);
				if (configOptions.measurementId) {
					debug.log("Instantiated Google Analytics with this configuration", configOptions);
					firebase.analytics();
				} else {
					debug.log("Google Analytics could not be instantiated. This is the configuration", configOptions);
				}
			});
		},
		async startVisit() {
			if(!this.visit.idMuseum) return;
			const startDateCondition = JSON.parse(localStorage.getItem("visitv2"))?.startDate;
			if (compareDatesToday(startDateCondition)) {
				let languagesV2 = localStorage.getItem('languagesV2');
			
				if (languagesV2) {
					languagesV2 = JSON.parse(languagesV2);
				} else {
          if (this.isNetworkOnline){
            languagesV2 = await MuseumsAPI.getLanguagesByMuseum(+this.visit.idMuseum);
          }
				}
				const languageId = languagesV2.find(e => e.code === this.visit.languageCode)?.id || languagesV2[0]?.id || 1;
				const languageCode = languagesV2.find(e => e.code === this.visit.languageCode)?.code || languagesV2[0]?.code || 'es_ES';
          if (this.isNetworkOnline){
            MuseumsAPI.registerVisitMuseumV2(+this.visit.idMuseum, languageId, languageCode, this.visit.idDevice, mutationTypes.SET_VISITV2, this.$store);
          }
				}
		}
	},
};
</script>

<style lang="scss">
@import '/theme/colors.scss';

html, body {
	height: 100% !important;
}
@font-face {
  font-family: 'Avenir';
  src: url('@/../assets/fonts/Avenir/AvenirLTStd-Black.otf') format('opentype');

}
/* Resets position and box-model */
 *:not(.step-richText *) {
 	padding: 0;
 	margin: 0;
 	font-size: 16px;
 	box-sizing: border-box;
 
 	// font-family: museum.code === 'malaga' ? 'Avenir', sans-serif : 'Montserrat', sans-serif ;
	
 	&:focus {
 		outline: none;
 	}
 }
.font-malaga{
	font-family:'Avenir', sans-serif!important;
}




.by-halls {
	font-size: 14px;
}

#app {
	height: 100%;
	overflow-y: auto;
	
	.new-content-available-toastr {
		position: absolute;
		bottom: 10px;
		right: 10px;
		left: 10px;
	}
}

.mm-icon {
	margin-bottom: -10px;
	
	&.green {
		color: $green
	}
	
	&.white {
		color: white;
	}
	
	&.red {
		color: $pink;
	}
	
	&.gray {
		color: $gray-light;
		// Use this to convert HEX to filter (https://codepen.io/sosuke/pen/Pjoqqp)
		filter: invert(60%) sepia(11%) saturate(16%) hue-rotate(346deg) brightness(93%) contrast(92%);
	}
	
	&.gray-light {
		color: $gray-lightest
	}
	
	&.big {
		font-size: 35px;
	}
}

.VueCarousel-wrapper {
	height: 100%;
	
	.VueCarousel-inner {
		height: 100% !important;
	}
}

.privacy-policy-link {
	font-size: 12px;
	color: rgba(white, 0.8);
}
.background{
	background-color: white;
}
</style>
