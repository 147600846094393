<template>
	<div class="content">
		<!-- <loading :active.sync="isLoading" :is-full-page="true" /> -->
		<Load v-if="isLoading"/>
		<section v-if="!isLoading && !downloadsError" class="layout" :style="cssVars">
			<div class="main">
				<div class="downloading">
					<div class="label">
						<p :style="fontMalaga">{{$i18n.t('downloading') }}</p>
					</div>
					<div class="dot-pulse" />
				</div>
				<div class="wrapper">
					<div class="progress-bar">
						<span class="progress-bar-fill" :style="{ 'width': (downloadProgress * 100) / totalCount + '%' }" />
					</div>
				</div>
				<h2 class="percentage">{{progressPercentage}}%</h2>
			</div>
		</section>
	</div>
</template>

<script>
const { debug } = require("@/misc/debug");
import * as mutationTypes from "@/store/mutation-types";
import { mapActions, mapState } from "vuex";
// import Loading from "vue-loading-overlay";
import 'vue-loading-overlay/dist/vue-loading.css';
import { setFullScreen } from "@/store/helpers";
import idb from "../../api/base/idb";
import Load from '../../components/Load.vue';

export default {
	name: "Downloads",

	components: {
		Load
		// Loading
	},

	filters: {
		capitalize: function (value) {
			if (!value) return '';
			value = value.toString();
			return value.charAt(0).toUpperCase() + value.slice(1);
		},

		humanSize: function (value) {
			//See more https://stackoverflow.com/a/14919494
			let bytes = Math.abs(value);
			const thresh = 1024;

			if (Math.abs(bytes) < thresh) {
				return bytes + ' B';
			}

			const units = ['Kb', 'Mb', 'Gb', 'Tb'];
			let unitIndex = -1;
			const r = 10 ** 2;

			do {
				bytes /= thresh;
				++unitIndex;
			} while (Math.round(Math.abs(bytes) * r) / r >= thresh && unitIndex < units.length - 1);


			return bytes.toFixed(2) + ' ' + units[unitIndex];
		}
	},

	data() {
		return {
			encrypted: this.$route.params.idMuseum,
			idMuseum: "0",
			showMenu: false,
			storage: null,
			downloadAll: 0,
			totalCount: 0,
			downloadProgress: 0,
			progressPercentage: 0,
			sectionExhibitions: {
				arrowColor: "#FFFFFF",
				lineColor: "#17779B",
				locale: {
					title: this.$i18n.t('temporary_exhibition')
				},
				fontColor: '#FFFFFF',
				fontSize: 32,
				enableSection: true,
				default: true,
			},
			sectionRoutes: {
				arrowColor: "#FFFFFF",
				lineColor: "#D1B490",
				locale: {
					title: this.$i18n.t('selected_routes')
				},
				fontColor: '#FFFFFF',
				fontSize: 32,
				enableSection: true,
				default: true,
			},
			sectionTimeline: {
				arrowColor: "#FFFFFF",
				lineColor: "#94AF49",
				locale: {
					title: this.$i18n.t('time_line')
				},
				fontColor: '#FFFFFF',
				fontSize: 32,
				enableSection: true,
				default: true,
			},
			keyboardCustomization: {
				bgColor: "#000000",
				recognitionColor: '#F06140',
				mainButtonsColor: '#72c4d0',
				mainButtonsTextColor: '#ffffff',
				mainButtonsCustomizationEnabled: false,
				artworkBackgroundColor: null,
				artworkTextColor: '#ffffff',
				artworkBackgroundImageUrl: null,
				artworkCustomizationEnabled: false,
				keysColor: '#39393a',
				keysActiveColor: '#e37d25',
				textColor: '#ffffff',
				eraseColor: '#8F8F8F',
				eraseIconColor: '#ffffff',
				okColor: '#72C4D0',
				okTextColor: '#ffffff',
				codeTextColor: '#ffffff',
				active: true,
			},
		};
	},

	computed: {
		...mapState({
			museum: state => state.museum.museum,
			isLoading: state => state.app.isLoading,
			downloads: state => state.museum.downloads,
			downloadsError: state => state.museum.downloadsError,
			navigationSections: state => state.navigationSection.navigationSections,
			navigationSectionsError: state => state.navigationSection.navigationSectionsError,
			sideMenu: state => state.museum.sideMenu,
			languageCode: state => state.app.visitv2.languageCode,
			visitv2: state => state.app.visitv2
		}),

		pageTitle() {
			let locationName = this.$i18n.t('downloads');

			let museumName = (this.museum?.name)
				? this.museum.name
				: this.museum?.locale?.name
					? this.museum.locale.name
					: "MuseumMate";

			return locationName + ' | ' + museumName;
		},

		cssVars() {
			if (!this.isLoading && this.museum) {
				return {
					'--bg-color': this.bgColor
						? ((this.sectionExhibitions?.active && this.sectionExhibitions.lineColor) ? this.sectionExhibitions.lineColor : '#17779b')
						: ((this.sectionRoutes?.active && this.sectionRoutes.lineColor) ? this.sectionRoutes.lineColor : '#d1b490'),
				};
			} else {
				return {};
			}
		},

		filteredExtras() {
			if (this.downloads) {
				return this.downloads.extras.filter(extra => this.museum[extra] !== null && this.museum[extra].length);
			} else {
				return [];
			}
		},
		fontMalaga() {
		let fontFamily = "'Montserrat-Light', sans-serif";
		if (this.museum && this.museum.code) {
		const isMuseumPicasso = this.museum.code === 'MPICASSOM';
		fontFamily = isMuseumPicasso ? "'Avenir', sans-serif" : "";
		}
		return { fontFamily };
  }
	},

	watch: {
		downloadProgress() {
			this.progressPercentage = Math.round((this.downloadProgress * 100) / this.totalCount);
			this.progressPercentage = Math.min(this.progressPercentage, 100);
			if (((this.downloadProgress * 100) / this.totalCount) === 100) {
				setTimeout(() => {
					this.$router.push({ name: "Options", params: { idMuseum: this.encrypted } });
				}, 500);
			}
		},
	},

	created() {
		debug.open("Downloads");
		this.$store.commit('app/' + mutationTypes.SET_IS_LOADING, true);
		this.$store.commit('app/' + mutationTypes.SET_VIEW_FROM, { name: 'Downloads', params: { idMuseum: this.encrypted } });
		this.$store.commit('museum/' + mutationTypes.SET_DOWNLOADS, null);
		this.fetchMuseumDetail(this.visitv2.idMuseum).then(() => {
			try {
				let decryption = (atob(this.encrypted));
				this.idMuseum = decryption.split('-')[0];

				this.loadMuseum(this.idMuseum)
					.then(async () => {
						this.keyboardCustomization = this.navigationSections.find((s) => s.sectionType === 'EXHIBITIONS');
						if (this.museum.keyboardCustomization) {
							if (this.museum.keyboardCustomization.active) {
								this.keyboardCustomization = this.museum.keyboardCustomization;
							}
						}
						this.getNavigationSections();

						let foundSection = this.navigationSections.find((s) => s.sectionType === 'EXHIBITIONS');
						this.sectionExhibitions = foundSection ? foundSection : this.sectionExhibitions;
						foundSection = this.navigationSections.find((s) => s.sectionType === 'ROUTES');
						this.sectionRoutes = foundSection ? foundSection : this.sectionRoutes;
						foundSection = this.navigationSections.find((s) => s.sectionType === 'TIMELINE');
						this.sectionTimeline = foundSection ? foundSection : this.sectionTimeline;

						await this.getDownloads();

						navigator.storage?.estimate().then((data) => this.storage = data);
					})
					.finally(() => {
						debug.close();
						this.$store.commit('app/' + mutationTypes.SET_IS_LOADING, false);
						this.downloadAllFunction();
					});
			} catch (e) {
				if (process.env.VUE_APP_DEBUG_MODE == "true") console.error("Error deciphering: ", e);
				debug.close();
				this.$store.commit('app/' + mutationTypes.SET_IS_LOADING, false);
			}
		});

	},

	methods: {
		...mapActions('app', ['loadMuseum']),
		...mapActions('navigationSection', ['getNavigationSections']),
		...mapActions('museum', ['getDownloads', 'storeInIDB', 'deleteElementFromIDB', 'fetchMuseumDetail']),

		setFullScreen(to = null) {
			setFullScreen();
			if (to) {
				this.$router.push(to);
			}
		},

		goToOptions() {
			setFullScreen();
			this.$router.push({ name: "Options", params: { idMuseum: this.encrypted } });
		},
		async saveIndexDb(id,parameter,storeName) {
			const model = { idDownloaded: id, download: parameter };
              idb.saveToDB(model,storeName);
        },


		async downloadAllFunction() {
			let emptyNumber = 0;
			let blob = await fetch(this.museum.topBarLogoImageUrl).then(r => r.blob());
			let blobAsString = await new Promise((resolve) => {
			const reader = new FileReader();
			reader.onloadend = () => resolve(reader.result);
			reader.readAsDataURL(blob);
			});
			localStorage.setItem("logo",blobAsString);
            await this.saveIndexDb(1,this.downloads,'downloadedLanguague');
			
			//JUST FOR COUNTING
			for (const exhibition of this.downloads.exhibitions) {
                let route = Object.assign({}, exhibition);
                let artworks = route.steps;
                for (const artwork of artworks) {
                    if (artwork.localizations.some(e => e.languageCode === this.languageCode)) {
                        emptyNumber = emptyNumber + 1;
                    }
                }
            }
			for (const originalRoute of this.downloads.routes) {
				let route = Object.assign({}, originalRoute);
				// let artworks = this.museum.artworks.filter(museumArtwork => route.artworkIDs.includes(museumArtwork.idArtwork));
				let artworks = this.museum.artworks;
				for (const artwork of artworks) {
					if (artwork.localizations.some(e => e.languageCode === this.languageCode)) {
						emptyNumber = emptyNumber + 1;
					}
				}
			}

			this.downloads.timelines.forEach(originalTimeline => {
				let timeline = Object.assign({}, originalTimeline);
				let stepsToDownload = timeline.steps.filter(step => !!step.idHall).map(hallStep => hallStep.idHall);
				let halls = this.museum.halls.filter(museumHall => stepsToDownload.includes(museumHall.idHall));
				for (const hall of halls) {
					let artworks = this.museum.artworks.filter(museumArtwork => hall.artworkIds.includes(museumArtwork.idArtwork));
					for (const artwork of artworks) {
						if (artwork.localizations.some(e => e.languageCode === this.languageCode)) {
							emptyNumber = emptyNumber + 1;
						}
					}
				}
			});

			this.filteredExtras.forEach(extra => {
				let elements = this.museum[extra];
				elements.forEach(() => {
					emptyNumber = emptyNumber + 1;
				});
			});

			this.totalCount = emptyNumber;

			//DOWNLOAD
			 
				for (const navigationOriginal of this.downloads.downloaded.navigationExtra) {
					// fixed to show save side menu image as blob

					 if (navigationOriginal.sectionType==="SIDE_MENU" && this.museum.code === "MPICASSOM" ){
					 	navigationOriginal.mainImageUrl = this.sideMenu.exploreMuseumBackgroundImageUrl;
					 }
                	 let route = Object.assign({},navigationOriginal );
					try {
						await this.storeInIDB({
						storeName: 'navigationSections',
						element: route
						});
			        }
				catch(e){
					console.log('An error has occurred',e);
			
				}
                
                }

			for (const exhibitionOriginal of this.downloads.exhibitions) {
                let route = Object.assign({}, exhibitionOriginal);
                await this.storeInIDB({
                    storeName: 'routes',
                    element: route
                });
                let exhibitions = route.steps;
                 if (this.museum.code === "MPICASSOM"){
					const findSteps = route.steps.map(elemento => elemento.idArtwork);
				    const matchingIdElements = this.museum.artworks.filter(elemento => findSteps.includes(elemento.idArtwork));
	                exhibitions = matchingIdElements;

				 }
	
				if(exhibitions) {
					
					try {
					for (const exhibition of exhibitions) {
					let exposure = Object.assign({}, exhibition);
                    await this.storeInIDB({
                        storeName: "artworks",
                        element: exposure
                    });
                    this.downloadProgress = this.downloadProgress + 1;
                }}
					catch(e){
                 console.log('error when saving exhibition',e);
					}
					

				 }
				
               
            }
			for (const originalRoute of this.downloads.routes) {
				let route = Object.assign({}, originalRoute);
				await this.storeInIDB({
					storeName: 'routes',
					element: route
				});

				// let artworks = this.museum.artworks.filter(museumArtwork => route.artworkIDs.includes(museumArtwork.idArtwork));
				let artworks = this.museum.artworks;
				for (const artwork of artworks) {
					await this.storeInIDB({
						storeName: "artworks",
						element: artwork
					});
					this.downloadProgress = this.downloadProgress + 1;
				}
			}

			this.downloads.timelines.forEach(async (originalTimeline) => {
				let timeline = Object.assign({}, originalTimeline);
				let stepsToDownload = timeline.steps.filter(step => !!step.idHall).map(hallStep => hallStep.idHall);
				let halls = this.museum.halls.filter(museumHall => stepsToDownload.includes(museumHall.idHall));
				for (const hall of halls) {
					await this.storeInIDB({
						storeName: "halls",
						element: hall
					});

					let artworks = this.museum.artworks.filter(museumArtwork => hall.artworkIds.includes(museumArtwork.idArtwork));
					debug.open("Downloading " + artworks.length + " artworks inside this hall");
					for (const artwork of artworks) {
						await this.storeInIDB({
							storeName: "artworks",
							element: artwork
						});
						this.downloadProgress = this.downloadProgress + 1;
					}

				}
			});

			this.filteredExtras.forEach(async (extra) => {
				let elements = this.museum[extra];
				for (const element of elements) {
					await this.storeInIDB({
						storeName: extra,
						element: element
					});

					this.downloadProgress = this.downloadProgress + 1;
				}
			});
			let exhibitionNavigation = this.navigationSections.find((element)=> element.sectionType === 'EXHIBITIONS') || {} ;
			if(exhibitionNavigation) {
				try {
				const findingExposureRoute = this.museum.routes.filter((element) => element.isExhibition === true);
				exhibitionNavigation.mainImageUrl = findingExposureRoute[0].mainImageUrl;
						await this.storeInIDB({
						storeName: 'navigationSections',
						element: exhibitionNavigation
						});
			        }
					catch(e){
                    console.log('An error occurred while saving display in navigation',e);
					}

			}
			
			

			
		},

		getImageUrl(url) {
			if (url instanceof Blob) {
				return URL.createObjectURL(url);
			}

			return url;
		}
	}
};
</script>

<style lang="scss" scoped>
@import '../../theme/colors';


.content {
	width: 100%;
	height: 100%;
}
.background{
		background-color:'white';
	}
.layout {
	width: 100%;
	height: 100%;
	display: grid;
	grid:
		"header" auto "main" 1fr "footer" 0%;
}

.logo-image {
	width: 100%;
	height: 100%;
	max-width: 350px;
}

.header {
	grid-area: header;
	background-color: var(--bg-color);
	text-align: center;

}

.main {
	grid-area: main;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	padding: 0 20px;
	background-color: white;
}

.wrapper {
	width: 100%;
}

.progress-bar {
	width: 100%;
	background-color: #e0e0e0;
	padding: 3px;
	border-radius: 100;
	box-shadow: inset 0 1px 3px rgba(0, 0, 0, .2);
	border-radius: 15px;
	overflow: hidden;
}

.progress-bar-fill {
	display: block;
	height: 22px;
	background-color: var(--bg-color);
	border-radius: 15px;
	transition: width 500ms ease-in-out;
	height: 10px;
}

.footer {
	grid-area: footer;
	background-color: var(--bg-color);
}

.downloading {
	display: flex;
	align-items: center;
	font-family: monospace !important;
	font-size: 10px;
	font-weight: 600;
	width: 100%;
	margin: 0 0 5px 3em;
}

.downloading .label {
	font-family: monospace !important;
}

.dot-pulse {
	position: relative;
	left: -9999px;
	width: 10px;
	height: 10px;
	border-radius: 5px;
	background-color: black;
	color: black;
	box-shadow: 9999px 0 0 -5px;
	animation: dot-pulse 1.5s infinite linear;
	animation-delay: 0.25s;
	margin-left: 20px;
}

.dot-pulse::before,
.dot-pulse::after {
	content: "";
	display: inline-block;
	position: absolute;
	top: 0;
	width: 10px;
	height: 10px;
	border-radius: 5px;
	background-color: black;
	color: black;
}

.dot-pulse::before {
	box-shadow: 9984px 0 0 -5px;
	animation: dot-pulse-before 1.5s infinite linear;
	animation-delay: 0s;
}

.dot-pulse::after {
	box-shadow: 10014px 0 0 -5px;
	animation: dot-pulse-after 1.5s infinite linear;
	animation-delay: 0.5s;
}
.percentage{
	margin-top: 10px;
}

@keyframes dot-pulse-before {
	0% {
		box-shadow: 9984px 0 0 -5px;
	}

	30% {
		box-shadow: 9984px 0 0 2px;
	}

	60%,
	100% {
		box-shadow: 9984px 0 0 -5px;
	}
}

@keyframes dot-pulse {
	0% {
		box-shadow: 9999px 0 0 -5px;
	}

	30% {
		box-shadow: 9999px 0 0 2px;
	}

	60%,
	100% {
		box-shadow: 9999px 0 0 -5px;
	}
}

@keyframes dot-pulse-after {
	0% {
		box-shadow: 10014px 0 0 -5px;
	}

	30% {
		box-shadow: 10014px 0 0 2px;
	}

	60%,
	100% {
		box-shadow: 10014px 0 0 -5px;
	}
	
}
</style>
