import api from "./base/API.js";
import { axiosv2 } from "@/api/base/API";
import moment from 'moment-timezone';
import { v4 as uuidv4 } from 'uuid';
import saveToLocalStorage from '../utils/saveTolocalStorage.js';

const resource = "Museum";
export default {
	get() {
		return api.get(resource);
	},

	getMuseum(id) {
		return api.get(resource + "/" + id);
	},

	getMuseumDetail(id) {// all detail museum
		 const visitV2 = JSON.parse(localStorage.getItem('visitv2'));
			return api.get(resource + `/Detail/v2/${id}/${visitV2?.idLanguage ?? 2}`);
		// return api.get(resource + `/Detail/${id}`);
	},

	checkAccessCode(id, code) {
		return api.get(resource + '/' + id + '/checkAccessCode?code=' + code);
	},

	// registerVisit(id, visit) {
	// 	return api.post(resource + '/' + id + '/v2/registerVisit', visit);
	// },
	async registerVisit(id, visit) {
		try {
		  const response = await api.post(resource + '/' + id + '/v2/registerVisit', visit);
		  return response; 
		} catch (error) {
		if(error.message === 'Network Error') {
			console.error('An error has occurred in the connection:', error);
			const request = {
			  id, visit
			};
			let arrayPendingRequest = [];
			const storedPendingRequests = localStorage.getItem('pendingRequests');
			if(storedPendingRequests === null){
				arrayPendingRequest.push(request);
				localStorage.setItem('pendingRequests', JSON.stringify(arrayPendingRequest));


			}else {
				let temp = JSON.parse(localStorage.getItem('pendingRequests'));
				arrayPendingRequest.push(...temp,request);
				localStorage.setItem('pendingRequests', JSON.stringify(arrayPendingRequest));
				
			}
			
		}
		console.log('error', error);
		}
	  },
	registerVisitMuseumV2(idMuseum, idLanguage, languageCode, idDevice, mutation, store) {
		return axiosv2.post(`visitMuseum`, {
			idMuseum,
			visitorToken: uuidv4(),
			idLanguage,
			idDevice,
			startDate: moment(new Date()).tz("Africa/Lome").format("YYYY-MM-DDTHH:mm:ss.SSSSZ"),
		})
			.then((response) => {
				const { data } = response;
				store.commit('app/' + mutation, {...data, languageCode}, { root: true });
				localStorage.setItem('visitv2', JSON.stringify({...data, languageCode}));
			})
			.catch((error) => {
				if(error.message === 'Network Error') {
					console.error('An error has occurred in the connection in update:', error);
					const request = {
						idMuseum, idLanguage, languageCode, idDevice, mutation, store
					};
					saveToLocalStorage(request,"pendingRequestregisterMuseum");
				}
				console.log('error', error);
			});
	},

	registerVisitRouteListV2(idVisit, idRoute) {
		return axiosv2.post(`visitRoute`, {
			idVisit,
			idRoute,
			startDate: moment(new Date()).tz("Africa/Lome").format("YYYY-MM-DDTHH:mm:ss.SSSSZ"),
		})
			.then(() => {
				
			})
			.catch((error) => {
				console.log('error', error);
			});
	},

	registerVisitArtworkV2(idVisit, idArtwork) {
		return axiosv2.post(`visitArtwork`, {
			idVisit,
			idArtwork,
			startDate: moment(new Date()).tz("Africa/Lome").format("YYYY-MM-DDTHH:mm:ss.SSSSZ"),
		})
			.then(() => {
			})
			.catch((error) => {
				if(error.message === 'Network Error') {
					console.error('An error has occurred in the connection in update:', error);
					const request = {
						idVisit, idArtwork
					};
					saveToLocalStorage(request,"pendingRequestVisitArtworkV2");
				}
				console.log('error', error);
			});
	},
	
	updateVisitMuseumV2(visitv2, mutation, store) {
		const data = { ...visitv2 };
		data.endDate = moment(new Date()).tz("Africa/Lome").format("YYYY-MM-DDTHH:mm:ss.SSSSZ");
		return axiosv2.put(`visitMuseum`, data)
			.then((response) => {
				const { data } = response;
				store.commit('app/' + mutation, data, { root: true });
				localStorage.setItem('visitv2', JSON.stringify(data));
			})
			.catch((error) => {
				if(error.message === 'Network Error') {
					console.error('An error has occurred in the connection in update:', error);
					const request = {
						visitv2, mutation, store
					};
					saveToLocalStorage(request,"pendingRequestupdateMuseumV2");
				}
				console.log('error', error);
			});
	},

	updateVisitRouteListV2(idVisit, idRoute) {
		return axiosv2.put(`visitRoute`, {
			idVisit,
			idRoute,
			endDate: moment(new Date()).tz("Africa/Lome").format("YYYY-MM-DDTHH:mm:ss.SSSSZ"),
		})
			.then(() => {

			})
			.catch((error) => {
				if(error.message === 'Network Error') {
					console.error('An error has occurred in the connection in update:', error);
					const request = {
						idVisit, idRoute
					};
					saveToLocalStorage(request,"pendingRequestupVisitRouteList");
				}
				console.log('error', error);
			});
	},

	updateVisitArtworkV2(idVisit, idArtwork, duration) {
		return axiosv2.put(`visitArtwork`, {
			idVisit,
			idArtwork,
			duration: parseInt(duration)
		})

			.then(() => {

			})
			.catch((error) => {
				if(error.message === 'Network Error') {
					console.error('An error has occurred in the connection in update:', error);
					const request = {
						idVisit, idArtwork, duration
					};
					saveToLocalStorage(request,"pendingRequestUpdateVisit");
					
				}
				console.log('error', error);

			});
	},

	getLanguagesByMuseum (idMuseum) {
		return axiosv2.get(`languages/${idMuseum}`)
		.then((response) => {
			localStorage.setItem('languagesV2', JSON.stringify(response.data));
			return response.data;
		})
		.catch((error) => {
			console.log('error', error);
		});
	}
};
