<template>
	<div class="content" :style="cssVars">
		<vue-headful
			:title="pageTitle"
		/>
		
		<loading :active.sync="isLoading"
		         :is-full-page="true"/>
		<!-- <Load v-if="isLoading"></Load> -->
		
		<div v-if="!isLoading && museum" class="container">
			<img class="bg-image" :src="museum.walktroughImageUrl" alt="">
		</div>
		
		<carousel v-if="!isLoading"
		          ref="carousel"
		          class="carousel"
		          :adjustableHeight="true"
		          :paginationEnabled="false"
		          :perPage="1"
		          :loop="true"
		          @page-change="changeSlide">
			<slide v-for="slide in slides" :key="slide.idSlide" class="slide">
				
				<main v-if="slide">
					<div v-if="slide.videoResource && slide.videoResource.locale" class="video" :style="cssVars">
						<video :id="'video-' + slide.index" :autoplay="index === slide.index" playsinline @ended="videoEnded()">
							<source :src="slide.videoResource.locale.url" type="video/mp4">
							Not supported
						</video>
						
						<div class="controls" :style="cssVars">
							<button title="replay" @click="replay()">
								<i class="replay material-icons mm-icon-replay white big"/>
							</button>
							<button title="volume" @click="toggleSound()">
								<i v-show="soundOn" class="sound material-icons mm-icon-volume-up white big"/>
								<i v-show="!soundOn" class="silence material-icons mm-icon-volume-mute white big"/>
							</button>
							<button title="play" @click="togglePlayPause()">
								<i v-show="!playing" class="play material-icons mm-icon-play white big"/>
								<i v-show="playing" class="pause material-icons mm-icon-pause white big"/>
							</button>
						</div>
					</div>
					
					<div v-else-if="slide.imageResource" class="image" :style="cssVars">
						<img class="image-source" :src="slide.imageResource.url" :alt="slide.imageResource.title">
					</div>
					
					<div v-if="slide.textResource" class="text">
						<h2 v-if="slide.textResource.title" class="title">{{ slide.textResource.title }}</h2>
						<p v-if="slide.textResource.locale.content" class="content" v-html="slide.textResource.locale.content"/>
					</div>
				</main>
			</slide>
		</carousel>
		
		<div class="dots">
			<div v-for="n in slides.length" :key="n"
			     class="dot"
			     :class="{'active': index === n-1}"
			     @click="$refs.carousel.goToPage(n-1)"/>
		</div>
		
		<transition name="appear">
			<footer v-show="index === slides.length - 1">
				<div class="footer-buttons">
					<button class="button-blue" @click="goToNextView()">
						<i class="mm-icon mm-icon-arrow-right white big"/>
					</button>
				</div>
			</footer>
		</transition>
	</div>
</template>

<script>
const {debug} = require("@/misc/debug");
import * as mutationTypes from "@/store/mutation-types";
import {mapActions, mapState} from "vuex";
import Loading from "vue-loading-overlay";
import { Carousel, Slide } from 'vue-carousel';
import {setFullScreen} from "@/store/helpers";
// import Load from '../../components/Load.vue';

export default {
	name: "Slides",
	
	components: {
		// Load,
		Loading,
		Carousel,
		Slide
	},
	
	data() {
		return {
			encrypted: this.$route.params.idMuseum,
			idMuseum: "0",
			playing: (!(this.slides && this.slides[0].videoResource)),
			soundOn: true,
			index: 0,
		};
	},
	
	computed: {
		...mapState({
			museum: state => state.museum.museum,
			customization: state => state.museum.customization,
			isLoading: state => state.app.isLoading,
			isNetworkOnline: state => state.app.isNetworkOnline,
			museumError: state => state.app.museumFetchingError,
			slides: state => state.slide.slides,
			slidesError: state => state.slide.slidesError,
		}),
		
		pageTitle() {
			let locationName = this.$i18n.t('walkthrough');
			let museumName = (this.museum?.name)
				? this.museum.name
				: this.museum?.locale?.name
					? this.museum.locale.name
					: "MuseumMate";
			
			return locationName + ' | ' + museumName;
		},
		
		cssVars() {
			return {
				'--height': (this.index === (this.slides.length-1)) ? '100% - 90px' : '100%',
				'--dots-bottom': (this.index === (this.slides.length-1)) ? '90px' : '0',
				'--filter-brightness': (this.slides[this.index]?.textResource) ? 'brightness(60%)' : '',
				'--main-buttons-color': (this.customization?.mainButtonsCustomizationEnabled) ? this.customization.mainButtonsColor : '#72c4d0',
				'--main-buttons-text-color': (this.customization?.mainButtonsCustomizationEnabled) ? this.customization.mainButtonsTextColor : '#ffffff',
			};
		}
	},
	
	created() {
		debug.log("[Slides] Creating view");
		this.$store.commit('app/' + mutationTypes.SET_IS_LOADING, true);
		
		try {
			let decryption = (atob(this.encrypted));
			this.idMuseum = decryption.split('-')[0];
			
			this.loadMuseum(this.idMuseum)
				.then(() => {
					this.getSlides();
					if(this.slidesError || (!this.slides?.length)) {
						debug.log("[Slides] As there are no slides, redirecting to the next view...");
						this.goToNextView();
					}
				})
				.finally(() => {
					this.$store.commit('app/' + mutationTypes.SET_IS_LOADING, false);
				});
		} catch(e) {
			if (process.env.VUE_APP_DEBUG_MODE == "true") console.error("Error deciphering: ", e);
			this.$store.commit('app/' + mutationTypes.SET_IS_LOADING, false);
		}
	},
	
	methods: {
		...mapActions('app', ['loadMuseum']),
		...mapActions('slide', ['getSlides']),
		
		goToNextView() {
			setFullScreen();
			localStorage.setItem('slides-seen', 'true');
			this.$router.push({ name: 'Languages', params: { idMuseum: this.encrypted} });
		},
		
		toggleSound() {
			try {
				let video = document.getElementById('video-' + this.index);
				if(video && this.soundOn) {
					video.volume = 0;
					this.soundOn = false;
				} else if(video) {
					video.volume = 1;
					this.soundOn = true;
				}
			} catch (e) {
				console.log(e);
			}
		},
		
		togglePlayPause() {
			try {
				let video = document.getElementById('video-' + this.index);
				if (video && (video.paused || video.ended)) {
					this.playing = true;
					video.play();
				} else if(video) {
					this.playing = false;
					video.pause();
				}
			} catch (e) {
				console.log(e);
			}
		},
		
		replay() {
			try {
				let currentVideo = document.getElementById('video-' + this.index);
				if(currentVideo) {
					currentVideo.currentTime = 0;
					currentVideo.play();
					this.playing = true;
				}
			} catch (e) {
				console.log(e);
			}
		},
		
		videoEnded() {
			if(this.index < this.slides.length - 1) {
				this.$refs.carousel.goToPage(this.index + 1);
			}
		},
		
		changeSlide(event) {
			try {
				let currentVideo = document.getElementById('video-' + this.index);
				if (currentVideo) {
					currentVideo.pause();
					this.playing = false;
				}
				
				let nextVideo = document.getElementById('video-' + event);
				if (nextVideo) {
					nextVideo.play();
					this.playing = true;
				}
				
				this.index = event;
			} catch (e) {
				console.log(e);
			}
		}
	}
};
</script>

<style lang="scss" scoped>
@import '../../theme/colors';

.content {
	height: 100%;
	
	.container {
		overflow: hidden;
		position: fixed;
		width: 100%;
		height: 100%;
		z-index: -1;
		
		.bg-image {
			width: 100%;
			height: 100%;
			filter: blur(20px);
			transform: scale(1.5);
		}
	}
	
	.carousel {
		height: 100%;
		
		.slide {
			height: 100%;
			
			main {
				height: 100%;
				display: flex;
				justify-content: center;
				align-items: center;
				
				.video {
					width: 100%;
					height: 100%;
					
					display: flex;
					justify-content: flex-end;
					align-items: center;
					
					video {
						filter: var(--filter-brightness);
						object-fit: cover;
						width: 100%;
						height: calc(var(--height));
						top: 0;
						position: fixed;
					}
					
					.controls {
						z-index: 2;
						display: flex;
						justify-content: center;
						align-content: center;
						position: absolute;
						bottom: var(--dots-bottom);
						padding: 0 20px 10px 0;
						
						button {
							background-color: transparent;
							border: none;
							
							i {
								color: white;
								font-size: 24px;
							}
							
							.play, .pause, .sound, .silence {
								margin-left: 10px;
							}
						}
					}
				}
				
				.image {
					width: 100%;
					height: 100%;
					
					.image-source {
						width: 100%;
						height: 100%;
						object-fit: cover;
						filter: var(--filter-brightness);
					}
				}
				
				.text {
					position: fixed;
					bottom: 0;
					
					width: 100%;
					max-height: 100%;
					
					display: flex;
					flex-direction: column;
					justify-content: center;
					align-content: center;
					
					padding: 140px 20px 140px;
					color: white;
					
					.title {
						font-size: 32px;
						font-weight: bold;
						margin-bottom: 20px;
					}
					
					.content {
						overflow-y: auto;
						width: 50%;
					}
				}
			}
		}
	}
	
	.dots {
		position: fixed;
		left: 0;
		bottom: var(--dots-bottom);
		padding: 20px;
		display: flex;
		align-items: center;
		
		.dot {
			border-radius: 100%;
			margin-right: 10px;
			width: 7px;
			height: 7px;
			background-color: #a2a2a2;
			
			&.active {
				width: 10px;
				height: 10px;
				background-color: white;
			}
		}
	}
	
	.appear-leave-active,
	.appear-enter-active {
		transition: 0.5s;
	}
	.appear-enter {
		transform: translate(0, 100%);
	}
	.appear-leave-to {
		transform: translate(0, 200%);
	}
	
	footer {
		position: fixed;
		bottom: 0;
		height: 90px;
		width: 100%;
		display: flex;
		flex-direction: column;
		justify-content: center;
		box-shadow: 0 10px 20px rgba(0,0,0,0.19), 0 6px 6px rgba(0,0,0,0.23);
		
		.footer-buttons {
			width: 100%;
			min-height: 90px;
			display: flex;
			justify-content: center;
			position: absolute;
			top: 0;
			
			button {
				width: 100%;
				border: none;
				text-transform: uppercase;
				display: flex;
				justify-content: center;
				align-items: center;
				
				&.button-blue {
					background-color: var(--main-buttons-color);
					color: var(--main-buttons-text-color);
					
					.mm-icon {
						color: var(--main-buttons-text-color);
					}
				}
			}
		}
	}
}
</style>
